import React, { forwardRef } from 'react'

function makeRGBA(degree) {
  const ratio = 1 - Math.abs(degree / 360)
  const colorVal = Math.floor(255 * ratio)
  const colorArray = [colorVal, colorVal, colorVal]
  return `rgba(${colorArray.join(',')},1)`
}

const CanonicalGradient = forwardRef((props, ref) => {
  const maskARef = React.useRef(null)
  const maskBRef = React.useRef(null)

  React.useEffect(() => {
    for (let i = 1; i < 360; i++) {
      const rect = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect',
      )
      rect.setAttribute('width', 75)
      rect.setAttribute('height', 75)
      rect.setAttribute('fill', makeRGBA(i))
      rect.setAttribute('transform', `rotate(${i} 75 75)`)

      if (i > 180) {
        maskBRef.current.appendChild(rect)
      } else {
        maskARef.current.appendChild(rect)
      }
    }
  }, [])

  return (
    <svg
      viewBox="0 0 150 150"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <defs>
        <clipPath id="overlapClip">
          <rect x="0" y="75" width="150" height="75" />
        </clipPath>
        <mask id="angleX">
          <g>
            <rect width="150" height="150" fill="white" />
            <g id="partA" ref={maskARef} />
            <g id="partB" ref={maskBRef} clipPath="url(#overlapClip)" />
          </g>
        </mask>
      </defs>
      <circle
        cx="75"
        cy="75"
        fill="rgba(255, 255, 255)"
        r="75"
        mask="url(#angleX)"
        transform="rotate(219, 75, 75)"
      />
    </svg>
  )
})

export default CanonicalGradient
