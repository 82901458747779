import React from 'react'
import styled, { css } from 'styled-components'
import GatsbyImage from 'gatsby-image'
import useServices from '../../hooks/use-services'
import ArrowLink from '../../ui/arrow-link'
import Animate from '../animate'

const StyledArrowLink = styled(ArrowLink)`
  font-size: 1rem;
  margin-top: 2vw;
  ${props => props.theme.media.sm`
    align-self: center;
    font-size: 1.1rem;
  `}
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
`

const TextWrapper = styled.div`
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  ${p => p.css};
  animation-name: ${p => p.theme.animations.landingPageText};
`

const Image = styled.div`
  flex-basis: 45%;
  width: 100%;
`

const ServiceItem = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
  ${props => props.theme.media.sm`
    &, &:nth-child(odd) {
      flex-direction: column-reverse;
    }
  `}
  &:not(:last-child) {
    ${TextWrapper}, ${Image} {
      margin-bottom: 6vw;
    }
    ${TextWrapper} {
      ${props => props.theme.media.sm`
        margin-bottom: 12vw;
      `}
    }
  }
`

const Text = styled.div`
  font-size: 1.1rem;
  text-align: justify;
  color: ${p => p.theme.text.black[200]};
  line-height: 1.9rem;
  ${props => props.theme.media.sm`
    font-size: 0.95rem;
  `};
`

const ServicesWrapper = styled.div`
  padding-top: 15vw;
  ${props => props.theme.media.sm`
    padding-top: 0;
  `};
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: 3.2rem;
  margin-bottom: 2rem;
  white-space: pre-wrap;
  ${props => props.theme.media.sm`
    text-align: center;
    font-size: 2.7rem;
  `}
`

const Divider = styled.div`
  border-left: 1px solid ${props => props.theme.palette.secondary};
  -webkit-border-image: ${props => {
    if (props.last)
      return 'linear-gradient(to bottom, #f4452e 60%, white 85%) 1 100%;'
    return props.theme.palette.secondary
  }};
  border-right-width: 0;
  position: absolute;
  left: 50%;
  height: 100%;
  top: 0;
  font-size: 1.2vw;
  ${props =>
    props.first &&
    css`
      &:before {
        content: '';
        position: absolute;
        height: 5vw;
        top: -5vw;
        border-left: 1px solid ${props => props.theme.palette.secondary};
        left: -1px;
      }
    `}
  &:after {
    content: "${props => props.content}";
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.palette.secondary};
    height: 6vw;
    width: 6vw;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.palette.secondary};
    position: absolute;
    top: calc(50% - 3vw);
    left: -3vw;
    background-color: white;
  }
    ${props => props.theme.media.sm`
    display: none;
  `}
`

const Services = styled.div`
  color: ${props => props.theme.palette.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.palette.secondary};
  position: absolute;
  top: calc(-5vw - 10vw);
  left: calc(50% - 5vw);
  font-size: 1.4vw;
  ${props => props.theme.media.sm`
    display: none;
  `}
`

const DevicesTitle = styled.div`
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3vw;
  display: none;
  ${props => props.theme.media.sm`
    display: block;
  `}
`

const LandingServices = ({ animate }) => {
  const { services, getImage } = useServices()
  return (
    <ServicesWrapper>
      <DevicesTitle>Services</DevicesTitle>
      {services.map((service, i) => (
        <ServiceItem key={i}>
          {i === 0 && <Services>SERVICES</Services>}
          <Animate duration="600ms" animate={animate}>
            {props => (
              <TextWrapper {...props}>
                <Title>{service.title}</Title>
                <Text>{service.texts.short}</Text>
                <StyledArrowLink
                  label="learn more"
                  to="/services/"
                  state={{ i }}
                  gradient
                />
              </TextWrapper>
            )}
          </Animate>
          <Divider
            content={`0${i + 1}`}
            last={i === services.length - 1}
            first={i === 0}
          />
          <Image>
            <StyledImage
              alt={service.title}
              title={service.title}
              fluid={getImage(i)}
              key={i}
            />
          </Image>
        </ServiceItem>
      ))}
    </ServicesWrapper>
  )
}

export default LandingServices
