import { graphql, useStaticQuery } from 'gatsby'

export default function useIllustration() {
  const { img } = useStaticQuery(
    graphql`
      query {
        img: allImageSharp(
          filter: { fluid: { src: { regex: "/illustration/" } } }
          sort: { order: ASC, fields: sizes___originalName }
        ) {
          edges {
            node {
              fluid(quality: 95, maxWidth: 4000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `,
  )
  const images = img.edges
  return {
    all: images,
    crypto: images.filter(img => img.node.fluid.src.match(/crypto/)),
    network: images.filter(img => img.node.fluid.src.match(/network/)),
    product: images.filter(img => img.node.fluid.src.match(/product/)),
    leaf: images.find(img => img.node.fluid.src.match(/leaf/)),
  }
}
