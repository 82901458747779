import React from 'react'
import Container from '../components/container'
import LandingBackground from '../components/landing/landing-background'
import LandingAboutUs from '../components/landing/landing-about-us'
import LandingClientsAndPartners from '../components/landing/landing-clients-partners'
import LandingQuestionsAndProjects from '../components/landing/landing-questions-projects'
import LandingMapAndContact from '../components/landing/landing-map-contact'
import LandingServices from '../components/landing/landing-services'
import SEO from '../components/seo'
import LandingPortfolio from '../components/landing/landing-portfolio'
import styled from 'styled-components'
import Testimonials from '../components/testimonials/testimonials'

const AboutUsWrapper = styled.div`
  position: relative;
  font-size: 3rem;
  line-height: 4.2rem;
  ${props => props.theme.media.sm`
    font-size: 2rem;
    line-height: 3rem;
  `};
`

const IndexPage = ({ animate }) => {
  return (
    <>
      <SEO title="Home" />
      <Container mb="15rem">
        <LandingBackground animate={animate} />
      </Container>
      <Container width="86vw" mb="10vmax">
        <LandingServices animate={animate} />
      </Container>
      <Container mb="10vmax">
        <LandingPortfolio animate={animate} limit={3} withTitle />
      </Container>
      <Container width="60vw" mb="10vmax">
        <AboutUsWrapper>
          <LandingAboutUs variant="short" withLink animate={animate} />
        </AboutUsWrapper>
      </Container>
      <Container width="86vw" mb="10vmax">
        <LandingClientsAndPartners />
      </Container>
      <Container width="60vw" mb="10vmax">
        <Testimonials />
      </Container>
      <LandingQuestionsAndProjects />
      <LandingMapAndContact />
    </>
  )
}

export default IndexPage
